/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
/* @font-face {
  font-family: 'Work Sans';
  src: url('./assets/fonts/WorkSans-VariableFont_wght.ttf');
} */

html,
body {
  height: 100%;
}

a {
  text-decoration: none !important;
}

.login {
  margin-top: 150px;
}

.btn-fundo-azul {
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  color: #ffffff;
  background-color: #6890e7;
  border-color: #6693f5;
}

.btn-fundo-azul:hover {
  color: #1e366a;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-fundo-claro {
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  color: #1e366a;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-fundo-claro:hover {
  color: #ffffff;
  background-color: #6890e7;
  border-color: #6890e7;
}

.form-group {
  margin-bottom: 0 !important;
  margin-top: 0.75rem;
}

table {
  width: 100%;
  margin-bottom: 0%;
  border-top-style: hidden;
}

.upload-input {
  margin-top: 10px;
}

.btn-voltar, .btn-voltar:link, .btn-voltar:hover{
  text-decoration: none;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* estilo barra de notificacao */
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: none !important;
  --mdc-snackbar-supporting-text-color: none !important;
  --mat-snack-bar-button-color: none !important;
}
.snackbar-danger {
  background-color: #f8d7dac0;
  color: #721c24;
}

.snackbar-danger button {
  background-color: #f8d7dac0;
  color: #721c24;
  border: none;
}

.snackbar-success {
  background-color: #d1e7ddc0;
  border: #155724;
  color: #155724;
}

.snackbar-success button {
  background-color: #d1e7ddc0;
  color: #155724;
  border: none;
}

.snackbar-warning {
  background-color: #fff3cdc0;
  color: #856404;
}

.snackbar-warning button {
  background-color: #fff3cdc0;
  color: #856404;
  border: none;
}
/* fim estilo barra de notificacao */

.primecap-blue {
  background-color: #1e366a;
}

.btn {
  margin-top: 0.3rem;
  margin-right: 0.1rem;
}

.btn-primary, .btn-primary:focus {
  background-color: #1e366a;
  border-color: #1e366a;
}

.btn-primary:hover, .btn.btn-primary:active {
  background-color: #192d5a !important;
  border-color: #192d5a !important;
}

.btn-primary:disabled {
  background-color: #2b3a5c;
  border-color: #2b3a5c;
}

a, a:hover {
  color: #000;
}

.div-card-socio {
  margin-top: 1.5%;
}
.card-socio {
  margin-bottom: 2px !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3b3c3d;
  border-radius: 100px;
}

.card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}

/* fix para o menu não alterar a altura quando expandir */
.mat-expansion-panel-header.mat-expanded {
  height: 48px !important;
}

.mat-expansion-panel-header {
  padding: 0 6px !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

/* fim do fix para o menu não alterar a altura quando expandir */

/* cor do background dos sub itens de menu */
.mat-expansion-panel-body {
  padding: 0 !important;
}

.sublist-item:hover {
  background-color: #252525 !important;
}

.mat-list-base {
  padding-top: 0 !important;
  -webkit-tap-highlight-color: transparent
}

.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0px;
}

.mat-icon {
  user-select: none;
}

/* inputs de upload dos cadastros de admin, assessor e investidor */
.input-group-text {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
}

:root {
  --cor-principal: #2b3968;
  --cor-texto-principal:
  --cor-fundo: #f5f5f5;
}

/* .material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
} */
